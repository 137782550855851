import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import GlobalStyle from '@/styles/GlobalStyles'
import { fakeTreatmentData } from '../../static/fakeTreatmentData'
import { Title, Paragraph } from '@/components/TextComponents/textStyles'
import { Tile, TileProps } from '@/components/BlockComponents/Tile'
import NotFound from '../pageComponents/404'

const Service = ({ data }) => {
  const _data = fakeTreatmentData

  // const dataTransformer = (data ):Array<TileProps> => {
  //   let newDataArr = []
  //   data.forEach((element) => {
  //     newDataArr.push({
  //       id: element?.id ?? '',
  //       title: element?.treatmentName ?? '',
  //       description: element?.treatmentShortDescription ?? '',
  //       image: element?.treatmentImage ?? ''
  //     })
  //   })
  //   return newDataArr
  // }

  const sanitizedData: Array<TileProps> = _data.map((element) => ({
    id: element?.id ?? '',
    title: element?.treatmentName ?? '',
    description: element?.treatmentShortDescription ?? '',
    image: element?.treatmentImage ?? ''
  }))

  if (!_data) {
    return <NotFound />
  }

  return (
    <main>
      <GlobalStyle />
      <Title level="4">Unser Behandlungs-Angebot</Title>
      <Paragraph level="1" isSmall>Reproductive health clinic in Hamburg</Paragraph>
      <TileWrapper>
        {!!sanitizedData && sanitizedData.map((treatmentOption) => {
          return (
            <Tile key={treatmentOption.id} {...treatmentOption}/>
          )}
        )}
      </TileWrapper>
    </main>
  )
}

const TileWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 10px;
  margin: 0 -5px 36px;
`

export default Service
