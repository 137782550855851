export const fakeTreatmentData = [
  {
    "id": "1",
    "treatmentName": "ICSI",
    "treatmentShortDescription": "Befruchtung außerhalb des Körpers mit Hilfe",
    "treatmentImage": "test_images/Illu_wissen_ICSI.png",
    "price": "€234,87",
    "tags": ["ICSI", "IUI"]
  },
  {
    "id": "2",
    "treatmentName": "IVF",
    "treatmentShortDescription": "Befruchtung außerhalb des Körpers",
    "treatmentImage": "test_images/Illu_wissen_IVF.png",
    "price": "€234,87",
    "tags": ["IVF", "IUI"]
  },
  {
    "id": "3",
    "treatmentName": "Kryo",
    "treatmentShortDescription": "Der Auftauzyklus",
    "treatmentImage": "test_images/Illu_wissen_KRYO.png",
    "price": "€439,15",
    "tags": ["KRYO"]
  }
]