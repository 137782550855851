import React from 'react'
import styled from "styled-components"
import { ColorPaletteMain as Colors } from '@/styles/ColorPalette'
import { Typography } from '@/styles/textstyles'

const NotFound: React.FC = () => (
  <main>
    <Title>Error 404</Title>
    <Image />
  </main>
);

const Title = styled.div`
  font-family: ${Typography.headers.h4};
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.5px;
  color: ${Colors.primaryColors.ink6};
  margin: 150px 0 30px;
`
const Image = styled.div`
  background-image: url("/img/error.png");
  background-size: 274px 253.65px;
  background-repeat: no-repeat;
  width: 274px;
  height: 253.65px;
  margin: auto;
`

export default NotFound;
