import React from 'react'
import styled from 'styled-components'
import { Label } from '@/components/TextComponents/textStyles'

export interface TileProps {
  id: string
  image: any
  title: string
  description: string
  onPress?: () => void
}

export const Tile: React.FC<TileProps> = (props: TileProps) => {
  return (
    <Wrapper onClick={props.onPress}>
      <ImwageWrapper>
        <Image src={props.image} alt="" />
      </ImwageWrapper>
      <Content>
        <Label level="2">{props.title}</Label>
        <Label level="1" isSmall>{props.description}</Label>
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #F8F8F8;
  border-radius: 8px;
  max-width: 160px;
  width: 100%;
  min-height: 220px;
  height: 100%;
`

const Content = styled.div`
  padding: 5px 13px 20px;
  height: 100%;
  text-align: center;
`

const ImwageWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 160px;
  height: 134px;
  padding: 8px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
`